import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import DicaExtra from "../components/DicaExtra"

import * as S from "../components/Pages/general"

const DisclaimerPage = () => (
  <Layout>
    <SEO
      title="Disclaimer"
      description="Nessa página fazemos os avisos legais sobre nossas atividades, alguma dúvida nos contate."
    />
    <S.GeneralWrapper>
      <S.GeneralTitle align="left" font>
        Disclaimer
      </S.GeneralTitle>
      <S.GeneralText>
        O site <S.GeneralLink to="/">Aprendendo Desenhar</S.GeneralLink> é um
        participante do programa de afiliados na plataforma Hotmart. Sendo
        assim, ganhamos comissões por nossas indicações.
      </S.GeneralText>
      <S.GeneralText>
        Isso significa que somos financeiramente compensados ​​por indicar
        produtos nesses sites.
      </S.GeneralText>
      <S.GeneralText>
        Contudo, buscamos sempre o melhor para você usuário e jamais pensaremos
        apenas em dinheiro, afinal o intuito maior do{" "}
        <S.GeneralLink to="/">Aprendendo Desenhar</S.GeneralLink> é que vocês
        possam aprender e realizar o seus sonhos.
      </S.GeneralText>
    </S.GeneralWrapper>
    <DicaExtra />
  </Layout>
)

export default DisclaimerPage
